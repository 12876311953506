import { useEffect, useState } from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
import router from './router';
import { getCurrentUser } from 'aws-amplify/auth';

function AuthenticatedGuard(){
  const [loginState, setLoginState] = useState<boolean>(null);
  const content = useRoutes(router);
  const location = useLocation();
  const navigate = useNavigate();
  
  //ログイン済みか確認
  useEffect(() => {
    const accessCurrentUser = async () => {
      try{
        const { username, userId, signInDetails } = await getCurrentUser();
        setLoginState(true);
      }catch{
        setLoginState(false);
      }
    };
    accessCurrentUser();
  }, []);
  useEffect(() => {
    if(location.pathname == "/" || location.pathname == "/login"){
      //ルート
      if(loginState == true){
        navigate("/SidebarLayout/Schedule");
      }//未ログインの場合そのまま
    }else{
      //ルートでない
      if(loginState == false){
        //かつ、未ログインの時、ルートへ遷移
        navigate("/");
      }//ログイン済の場合そのまま
    }
  }, [loginState]);

  return (
    <>
      {loginState == null ? <></> : content}
    </>
  );
};

export default AuthenticatedGuard;
