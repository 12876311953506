import { FC, useState, createContext, useEffect } from 'react';
import axios from 'axios'
import { getAuthToken,getLoginId } from "src/common/cognito"

type SidebarContext = {
  sidebarToggle: any;
  scheduleInfoArray: ScheduleProps[];
  taskInfoArray: TaskProps[];
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

interface ScheduleProps {
  scheduleId : string;
  title : string;
  start : string;
  end : string;
  description : string;
}

interface TaskProps {
  taskId: string;
  title: string;
  description:string;
}

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [scheduleInfoArray, setScheduleInfoArray] = useState([]);
  const [taskInfoArray, setTaskInfoArray] = useState([]);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  async function getSchedule() {
    console.log("call getSchedule");
    const idToken = await getAuthToken();
    const LoginId = await getLoginId();
    if(idToken == ""){
      console.log("cancel call getSchedule");
      return;
    }
    const headerdata = {
      Authorization: idToken,
    }

    const param = {
      id: LoginId,
    }

    await axios
      .get(process.env.REACT_APP_BASE_API_URL + "/test", { headers: headerdata,params:param })
      .then((res) => {
        if(res.data.result){
          console.log("schedule:",res.data.arr);
          setScheduleInfoArray( res.data.arr );
        }else{
          console.log("Failed")
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  async function getTask() {
    console.log("call getTask");
    const idToken = await getAuthToken();
    const LoginId = await getLoginId();
    if(idToken == ""){
      console.log("cancel call getTask");
      return;
    }
    const headerdata = {
      Authorization: idToken,
    }

    const param = {
      userId: LoginId,
    }

    await axios
      .get(process.env.REACT_APP_BASE_API_URL + "/task", { headers: headerdata,params:param })
      .then((res) => {
        if(res.data.result){
          console.log("Task:",res.data.allTasks);
          setTaskInfoArray( res.data.allTasks );
        }else{
          console.log("Failed")
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  useEffect( () => {
    getSchedule();
    getTask();
  },[]);

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, scheduleInfoArray, taskInfoArray, toggleSidebar, closeSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
