import { FC, useState, createContext, useCallback } from 'react';

type LoginUserContext = {
  contextMailAddress: string;
  keepMailAddress: (mailAddress: string) => void;
};

export const LoginUserContext = createContext<LoginUserContext>(
  {} as LoginUserContext
);

export const LoginUserProvider: FC = ({ children }) => {
  const [contextMailAddress, setMailAddress] = useState<string>("");
  const keepMailAddress = useCallback((mailAddress: string): void => {
    setMailAddress(mailAddress);
  }, []);

  return (
    <LoginUserContext.Provider
      value={{ contextMailAddress, keepMailAddress }}
    >
      {children}
    </LoginUserContext.Provider>
  );
};
