import { 
  Box,
  Typography,
  Button,
  useTheme,
  TextField,
  Divider,
 } from '@mui/material';
import Modal from '@mui/material/Modal';

import { FC, ReactNode, useState, useEffect, useContext } from 'react';

import dayjs from 'dayjs';
import axios from 'axios';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { getAuthToken, getLoginId } from 'src/common/cognito';
import { LoginUserContext } from 'src/contexts/LoginUserContext';
import { SidebarContext } from 'src/contexts/SidebarContext';

import { nanoid } from "nanoid";
import { Schedule } from '@mui/icons-material';
import { set } from 'date-fns';

interface ScheduleLayoutProps {
  children?: ReactNode;
}

interface ScheduleProps {
  scheduleId : string;
  title : string;
  start : string;
  end : string;
  description: string;
}

const ScheduleLayout: FC<ScheduleLayoutProps> = () => {
  const { scheduleInfoArray } = useContext(SidebarContext);
  const theme = useTheme();
  const [scheduleData,setScheduleData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [title,setTitle] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeScheduleId,setActiveScheduleId] = useState();
  const [action,setAction] = useState(null);
  const [description,setDescription] = useState("");
  const [plannedTime,setPlannedTime] = useState(null);
  const [actualTime,setActualTime] = useState(null);

  useEffect( () => {
    setScheduleData([...scheduleInfoArray]);
  },[scheduleInfoArray]);

  async function saveSchedule(id:String){
    const accessToken = await getAuthToken();
    const LoginId = await getLoginId();

    const headerdata = {
      Authorization: accessToken,
    }

    const param = {
      id: LoginId,
      scheduleId:id,
      title:title,
      start:startDate,
      end:endDate,
      description:description,
    }
    
    await axios
      .post(process.env.REACT_APP_BASE_API_URL + "/test", param, { headers: headerdata })
      .then((res) => {
        if(res.data.result){
          // 正常終了
        }else{
          // 異常終了
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  async function deleteSchedule(id:String){
    const accessToken = await getAuthToken();
    const LoginId = await getLoginId();

    const headerdata = {
      Authorization: accessToken,
    }

    const param = {
      userId: LoginId,
      scheduleId:id,
    }
    
    await axios
      .delete(process.env.REACT_APP_BASE_API_URL + "/test", { headers: headerdata,params:param })
      .then((res) => {
        if(res.data.result){
          // 正常終了
        }else{
          // 異常終了
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  async function updateSchedule(id:String){
    const accessToken = await getAuthToken();
    const LoginId = await getLoginId();

    const headerdata = {
      Authorization: accessToken,
    }

    const param = {
      userId: LoginId,
      scheduleId:id,
      title:title,
      start:startDate,
      end:endDate,
      description:description,
    }
    
    await axios
      .put(process.env.REACT_APP_BASE_API_URL + "/test", param, { headers: headerdata })
      .then((res) => {
        if(res.data.result){
          // 正常終了
        }else{
          // 異常終了
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  function handleDateSelect(selectInfo){
    setStartDate(dayjs(selectInfo.start));
    setEndDate(dayjs(selectInfo.end));
    setPlannedTime((selectInfo.end.getTime()-selectInfo.start.getTime()) / 3600000 );
    setAction("Add Event");
    setTitle("");
    setDescription("");
    setModalOpen(true);
  }

  function handleSave (){
    const id = nanoid();
    setScheduleData([
      ...scheduleData,
      {
        scheduleId : id,
        title : title,
        start : startDate.format("YYYY-MM-DDTHH:mm:ss"),
        end : endDate.format("YYYY-MM-DDTHH:mm:ss"),
        description: description,
      }
    ]);
    saveSchedule(id);
    setModalOpen(false);
  }

  function handleModalClose(){
    setModalOpen(false);
  }

  function handleEventClick(info){
    setAction("Update Event");
    setPlannedTime((info.event.end.getTime()-info.event.start.getTime()) / 3600000 );
    setTitle(info.event.title);
    setDescription(info.event.extendedProps.description);
    setStartDate(dayjs(info.event.start));
    setEndDate(dayjs(info.event.end));
    setActiveScheduleId(info.event.extendedProps.scheduleId);
    setModalOpen(true);
  }

  function handleDelete(){
    deleteSchedule(activeScheduleId);
    setScheduleData(scheduleData.filter((schedule) => schedule.scheduleId !== activeScheduleId) );
    setModalOpen(false);
  }

  function handleUpdate(){
    updateSchedule(activeScheduleId);
    setScheduleData(scheduleData.map(schedule => 
      schedule.scheduleId === activeScheduleId
        ? { ...schedule, title, startDate, endDate,description }
        : schedule
    ));
    setModalOpen(false);
  }


  return (
    <Box
      sx={{
        p: 2,
        [theme.breakpoints.down('sm')]: {
          p: 1,
        },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          mb: 3,
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
          },
        }}
      >
        スケジュール
      </Typography>

      <FullCalendar
         locale='ja'
         plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
         initialView="timeGridWeek"
         headerToolbar={{
           left: 'prev,next today',
           center: 'title',
           right: 'dayGridMonth,timeGridWeek,timeGridDay'
         }}
         views={{
          timeGridWeek:{
            slotMinTime: '06:00:00',
            slotMaxTime: '22:00:00'
          },
          timeGridDay:{
            slotMinTime: '06:00:00',
            slotMaxTime: '22:00:00'
          }
        }}
        //  businessHours={true}
         selectable={true}
         events={scheduleData}
         select={handleDateSelect}
         eventClick={handleEventClick}
         height="auto"
         contentHeight="auto"
      />

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '90%',
            borderRadius:'10px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY:'auto',
            [theme.breakpoints.down('sm')]: {
              width: '90%',
              p: 2,
            },
          }}
        >
          <Typography id="modal-modal-title" variant="h1" component="h2">
            {action === "Update Event" ? "イベントを更新" : "新しいイベント"}
          </Typography>
          <TextField
            fullWidth
            label="タイトル"
            variant="outlined"
            margin="normal"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <Typography variant='h3' sx={{ mt: 2, [theme.breakpoints.down('sm')]: { fontSize: '1.2rem' } }}>
            開始
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ mr: 1 }}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              // renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
            <TimePicker
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              // renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
          </LocalizationProvider>

          <Typography variant='h3' sx={{ mt: 2, [theme.breakpoints.down('sm')]: { fontSize: '1.2rem' } }}>
            終了
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ mr: 1 }}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
            />
            <TimePicker
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            label="カテゴリ"
            variant="outlined"
            margin="normal"
            // value={title}
            // onChange={(e) => {
            //   setTitle(e.target.value);
            // }}
          />
          <Typography variant='h4' sx={{ mt: 1,ml: 1, [theme.breakpoints.down('sm')]: { fontSize: '1.2rem' } }}>
            作業予定時間(h) : {plannedTime}
          </Typography>
          <TextField
            label="作業実績時間(h)"
            variant="outlined"
            margin="normal"
            type="number"
            value={actualTime}
            onChange={(e) => {
              setActualTime(e.target.value);
            }}
          />
          <TextField
            fullWidth
            label="概要"
            variant="outlined"
            margin="normal"
            multiline
            minRows="7"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />

          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={action === "Update Event" ? handleUpdate : handleSave}
              sx={{ mr: 1 }}
            >
              {action === "Update Event" ? "更新" : "保存"}
            </Button>
            {action === "Update Event" &&
              <Button
                variant="contained"
                onClick={handleDelete}
                sx={{ mr: 1 }}
              >
                削除
              </Button>
            }
            <Button onClick={handleModalClose}>
              閉じる
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ScheduleLayout;