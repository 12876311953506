import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import HomeLayout from 'src/layouts/HomeLayout';
import ScheduleLayout from './common/ScheduleLayout';
import TaskLayout from './common/TaskLayout';
import AILayout from './common/AILayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('src/common/Login')));
const Register = Loader(lazy(() => import('src/common/Register')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: 'Login',
        element: <Navigate to="/" replace />
      },
      {
        path: 'Register',
        element: <Register />
      }
      // {
      //   path: '*',
      //   element: <Status404 />
      // }
    ]
  },
  {
    path: 'SidebarLayout',
    element: <SidebarLayout />,
    children: [
      {
        path: 'Home',
        element: <HomeLayout />
      },
      {
        path: 'Schedule',
        element: <ScheduleLayout />
      },
      {
        path: 'Task',
        element: <TaskLayout />
      },
      {
        path: 'ProductScheduleByAI',
        element: <AILayout />
      }
    ]
  }
];

export default routes;
