import { FC, ReactNode, useState } from 'react';
import {
  useTheme,
  Card,
  Grid,
  Typography,
  Modal,
  Box,
  Divider,
  TextField,
  Button,
} from '@mui/material'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { TaskProps } from '../..';

interface TaskItemLayoutProps {
  children?: ReactNode;
  task : TaskProps;
  deleteTask:(id:string) => void;
  editTask:(task:TaskProps) => void;
}

const TaskItemLayout: FC<TaskItemLayoutProps> = ({task,deleteTask,editTask}) => {
  const theme = useTheme();
  const [modalOpen,setModalOpen] = useState(false);
  const [title,setTitle] = useState(task.title);
  const [description,setDescription] = useState(task.description);

  function handleOpenEdit(){
    setModalOpen(true);
  }

  function handleCloseEdit(){
    setModalOpen(false);
  }

  function handleEditSave(){
    editTask({...task,title:title,description:description});
    
    setModalOpen(false);
  }

  return (
    <>
      <Card 
        key={task.taskId}
        sx={{ mt:2, p:1, background:"inherit" }}
        onClick={handleOpenEdit}
      >
        <Grid container>
          <Grid item sx={{flexGrow:1}}>
            <Typography variant='h4' sx={{ml:2,height:"100%",lineHeight:'30px'}}>{task.title}</Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" size="small" onClick={() => deleteTask(task.taskId)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Card>

      <Modal
        open={modalOpen}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            borderRadius:'10px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            [theme.breakpoints.down('sm')]: {
              width: '90%',
              p: 2,
            },
          }}
        >
          <Typography variant='h1'>Edit</Typography>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mb: theme.spacing(3),
              background: theme.colors.alpha.black[100],
            }}
          />
          <Typography variant='h3'>Title</Typography>
          <TextField 
            variant="outlined"
            placeholder='タイトル'
            required
            defaultValue={task.title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <Typography variant='h3' sx={{ mt:2 }}>Description</Typography>
          <TextField 
            variant="outlined"
            placeholder='詳細'
            multiline
            minRows="7"
            fullWidth 
            defaultValue={task.description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <Box sx={{mt:2}}>
            <Button 
              variant="contained" 
              onClick={handleEditSave}
            >
              保存
            </Button>
            <Button 
              onClick={handleCloseEdit}
              sx={{ml:1}}
            >
              閉じる
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
export default TaskItemLayout;