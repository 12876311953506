import * as Auth from "aws-amplify/auth";

export async function getAuthToken() {
  try{
    const session = await Auth.fetchAuthSession();
    const accessToken = session.tokens.accessToken.toString();
    // console.log("session.tokens.idToken.payload.exp");
    // console.log(session.tokens.signInDetails.loginId);
    return accessToken;
    // return "OK";
  }catch{
    return;
  }
}

export async function getLoginId() {
  try{
    const session = await Auth.fetchAuthSession();
    const loginId = session.tokens.signInDetails.loginId.toString();
    // console.log("session.tokens.idToken.payload.exp");
    // console.log(session.tokens.signInDetails.loginId);
    return loginId;
    // return "OK";
  }catch{
    return;
  }
}