import { FC, ReactNode, useContext, useState, useEffect } from 'react';
import { nanoid } from "nanoid";
import {
  DndContext,
  DragOverlay,
  DragEndEvent,
  DragStartEvent,
} from '@dnd-kit/core';

import {
  Box,
  useTheme,
  Typography,
  Grid,
  Divider,
  Button,
  Modal,
  TextField,
  Card,
} from '@mui/material';

import TaskList from './TaskList';
import TaskItem from './TaskList/TaskItem';

import axios from 'axios';
import { getAuthToken, getLoginId } from 'src/common/cognito';
import { SidebarContext } from 'src/contexts/SidebarContext';


interface TaskLayoutProps {
  children?: ReactNode;
}

export interface TaskProps {
  taskId : string;
  title : string;
  description : string;
}

const TaskLayout: FC<TaskLayoutProps> = () => {
  const theme = useTheme();
  const { taskInfoArray } = useContext(SidebarContext);
  const [allTaskList, setAllTaskList] = useState<TaskProps[]>([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [title,setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [activeDrag,setActiveDrag] = useState<TaskProps>();

  useEffect( () => {
    setAllTaskList([...taskInfoArray]);
  },[taskInfoArray]);
  
  async function addTask(id:String){
    const accessToken = await getAuthToken();
    const LoginId = await getLoginId();

    const headerdata = {
      Authorization: accessToken,
    }

    const param = {
      userId: LoginId,
      taskId: id,
      title:title,
      description: description,
    }
    
    await axios
      .post(process.env.REACT_APP_BASE_API_URL + "/task", param, { headers: headerdata })
      .then((res) => {
        if(res.data.result){
          // 正常終了
        }else{
          // 異常終了
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  async function deleteTask(id:String){
    const accessToken = await getAuthToken();
    const LoginId = await getLoginId();

    const headerdata = {
      Authorization: accessToken,
    }

    const param = {
      userId: LoginId,
      taskId:id,
    }
    
    await axios
      .delete(process.env.REACT_APP_BASE_API_URL + "/task", { headers: headerdata,params:param })
      .then((res) => {
        if(res.data.result){
          // 正常終了
        }else{
          // 異常終了
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  async function updateTask(EditedTask:TaskProps){
    const accessToken = await getAuthToken();
    const LoginId = await getLoginId();

    const headerdata = {
      Authorization: accessToken,
    }

    const param = {
      userId: LoginId,
      taskId:EditedTask.taskId,
      title:EditedTask.title,
      description:EditedTask.description,
    }
    console.log(param);
    
    await axios
      .put(process.env.REACT_APP_BASE_API_URL + "/task", param, { headers: headerdata })
      .then((res) => {
        if(res.data.result){
          // 正常終了
        }else{
          // 異常終了
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  function handleAddTask(){
    setTitle("");
    setDescription("");
    setAddModalOpen(true);
  }

  function handleAddModalClose(){
    setAddModalOpen(false);
  }

  function handleAddSave(){
    const id = nanoid();
    setAllTaskList([
      ...allTaskList,
      {
        taskId:id,
        title: title,
        description: description,
      }
    ]);
    addTask(id);
    setAddModalOpen(false);
  }

  function handleDelete(id: String){
    setAllTaskList(allTaskList.filter((task) => task.taskId != id));
    deleteTask(id);
  }

  function handleEdit(EditedTask:TaskProps){
    setAllTaskList(
      allTaskList.map(task => 
      task.taskId === EditedTask.taskId
        ? EditedTask
        : task
    ));
    updateTask(EditedTask);
  }

  return (
    <Box sx={{ width:"100%", p:2 }}>
      <Grid sx={{p:2}}> 
        <Typography variant='h3'>タスク管理</Typography>
      </Grid>
      <Grid sx={{p:2}}>
        <Button variant='contained' onClick={handleAddTask}>追加</Button>
      </Grid>
      
      <Box sx={{ height:"100%", width:"100%", p:2 }}>
      <Card 
        sx={{ 
          p:2,
          mt: 0,
          background: theme.colors.alpha.black[10],
        }}
      >
        <Typography variant='h3'>ToDo</Typography>
        <Divider
          sx={{
            mt: theme.spacing(3),
            background: theme.colors.alpha.black[100],
          }}
        />
        <Box sx={{ minHeight: "30px" }}>
          {allTaskList.map((task) => {
            return(
              <TaskItem key={task.taskId} task={task} deleteTask={handleDelete} editTask={handleEdit}/>
            );
          })}
        </Box>
      </Card>
      </Box>
      <DragOverlay>
        <TaskItem task={activeDrag} deleteTask={handleDelete} editTask={handleEdit}/>
      </DragOverlay>

      <Modal
        open={addModalOpen}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            [theme.breakpoints.down('sm')]: {
              width: '90%',
              p: 2,
            },
          }}
        >
          <Typography variant='h1'>Add Task</Typography>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mb: theme.spacing(3),
              background: theme.colors.alpha.black[100],
            }}
          />

          <Typography variant='h3'>Title</Typography>
          <TextField 
            variant="outlined"
            placeholder='タイトル'
            required
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />

          <Typography variant='h3' sx={{ mt:2 }}>Description</Typography>
          <TextField 
            variant="outlined"
            placeholder='詳細'
            multiline
            minRows="7"
            fullWidth 
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <Box sx={{mt:2}}>
            <Button 
              variant="contained" 
              onClick={handleAddSave}
            >
              保存
            </Button>
            <Button 
              onClick={handleAddModalClose}
              sx={{ml:1}}
            >
              閉じる
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
export default TaskLayout;