import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography,Modal, useTheme } from '@mui/material';
import { getAuthToken, getLoginId } from 'src/common/cognito';


function AILayout() {
  const theme = useTheme();
  const [goal, setGoal] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [availableHours, setAvailableHours] = useState('');
  const [tasksAndSchedule, setTasksAndSchedule] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [details,setDetails] = useState("");

  const handleGenerate = async (event) => {
    const accessToken = await getAuthToken();
    const LoginId = await getLoginId();

    const headerdata = {
      Authorization: accessToken,
    }

    const param = {
      userId: LoginId,
      goal: goal,
      startDate: startDate,
      endDate: endDate,
      availableHours: availableHours,
      details: details,
    }

    await axios
    .get(process.env.REACT_APP_BASE_API_URL + "/openapi", {headers: headerdata,params:param})
    .then((res) => {
      if(res.data.result){
        // 正常終了
        console.log(res.data.tasksAndSchedule);
        setTasksAndSchedule(res.data.tasksAndSchedule);
        setModalOpen(true);
      }else{
        // 異常終了
        console.log(res.data.result);
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  };

  const handleSave = async () => {
    const accessToken = await getAuthToken();
    const LoginId = await getLoginId();

    const headerdata = {
      Authorization: accessToken,
    }

    const param = {
      userId: LoginId,
      tasksAndSchedule:tasksAndSchedule
    }

    await axios
    .post(process.env.REACT_APP_BASE_API_URL + "/openapi",param, {headers: headerdata})
    .then((res) => {
      if(res.data.result){
        // 正常終了
        setModalOpen(false);
      }else{
        // 異常終了
        console.log(res.data.result);
        console.log("izyou");
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  };

  function handleModalClose(){
    setModalOpen(false);
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        スケジュール生成
      </Typography>
      <TextField
        label="目標"
        variant="outlined"
        fullWidth
        margin="normal"
        value={goal}
        onChange={(e) => setGoal(e.target.value)}
      />
      <TextField
        label="開始日"
        type="date"
        variant="outlined"
        margin="normal"
        sx={{ mr: 2 }}
        InputLabelProps={{ shrink: true }}
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <TextField
        label="終了日"
        type="date"
        variant="outlined"
        margin="normal"
        sx={{ mr: 2 }}
        InputLabelProps={{ shrink: true }}
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
      <TextField
        label="一日に使える時間"
        type="number"
        variant="outlined"
        margin="normal"
        value={availableHours}
        onChange={(e) => setAvailableHours(e.target.value)}
      />
      <TextField
        label="詳細"
        variant="outlined"
        fullWidth
        multiline
        minRows="3"
        margin="normal"
        value={details}
        onChange={(e) => setDetails(e.target.value)}
      />
      <Button onClick={handleGenerate} variant="contained" color="primary" sx={{ mt: 2 }}>
        スケジュールを生成
      </Button>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '90%',
            borderRadius:'10px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY:'auto',
            [theme.breakpoints.down('sm')]: {
              width: '90%',
              p: 2,
            },
          }}
        >
          <Typography variant="h5">生成されたスケジュール</Typography>
          <ul>
            {tasksAndSchedule.map((task) => (
              <li key={task.scheduleId}>
                <Typography variant="body1"><strong>タイトル:</strong> {task.title}</Typography>
                <Typography variant="body2"><strong>開始:</strong> {task.start}</Typography>
                <Typography variant="body2"><strong>終了:</strong> {task.end}</Typography>
                <Typography variant="body2"><strong>説明:</strong> {task.description}</Typography>
              </li>
            ))}
          </ul>
          <Button onClick={handleSave} variant="contained" color="secondary">
            スケジュールを保存
          </Button>
          <Button onClick={handleModalClose} sx={{ml:2}}>
            閉じる
          </Button>
        </Box>
      </Modal>

    </Box>
  );
}

export default AILayout;