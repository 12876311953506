import { ResourcesConfig } from "aws-amplify";

export const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
      identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
      signUpVerificationMethod: "code",
      allowGuestAccess: true,
      region: process.env.REACT_APP_AUTH_REGION,
    },
  }
} as ResourcesConfig;
